import toNumber from 'lodash/toNumber'
import moment from 'moment'

const Base64Encode = (val) => {
    return btoa(val)
}

const FormatNumber = (val) => {
    return (val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

// https://stackoverflow.com/a/61994179
const FormatCurrency = (number, decimals = 2, decimalSeparator = ',', thousandsSeparator = '.') => {
    const roundedNumber = toNumber(number).toFixed(decimals);
    let integerPart = '',
        fractionalPart = '';
    if (decimals == 0) {
        integerPart = roundedNumber;
        decimalSeparator = '';
    } else {
        let numberParts = roundedNumber.split('.');
        integerPart = numberParts[0];
        fractionalPart = numberParts[1];
    }
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandsSeparator}`);
    return `${integerPart}${decimalSeparator}${fractionalPart}`;
}

const DateFormat = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : null
}

const DateFormatMonth = (date) => {
    return date ? moment(date).format('YYYY-MM') : null
}

export {
    Base64Encode,
    FormatNumber,
    FormatCurrency,
    DateFormat,
    DateFormatMonth,
}